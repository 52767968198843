import axiosInstance from "../axios/axios";
import { getAuthToken } from "../util/localStorage.util";
import { profileLinkRequest } from "../interfaces/profileLinkRequest";

export const uploadProfilePicture = async (
  data: FormData
): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post("/individual/upload-profile-image", data, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const loadImage = async (url: string): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(url, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      responseType: "arraybuffer",
    });
    // let w: any = window.open("");
    // const path = new Blob([response.data], { type: "application/pdf" });
    // var fileURL = URL.createObjectURL(path);
    // const link = document.createElement("a");
    // link.innerHTML = path;
    // window.open(fileURL);
    // link.setAttribute("download", `employee.xls`);
    // w.document.write(link.outerHTML);
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const changePassword = async (data: {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
}): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post("/individual/change-password", data, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const changePhoneNumber = async (data: {
  mobile_number: string;
}): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post("/individual/change-mobile-number", data, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const changeBankDetail = async (data: {
  mobile_number: string;
}): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post("/policy/updateBank", data, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const submitOTPToChangePassword = async (data: {
  otp: string;
}): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post(
      "/individual//validate-change-mobile-number-otp",
      data,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};

export const requestProfileLink = async (
  data: profileLinkRequest
): Promise<[boolean, any]> => {
  try {
    await axiosInstance.post("/", data, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [false, error];
  }
};
